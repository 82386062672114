<template>
    <div>

        <Portfolio :props-data="data"/>
    </div>
</template>

<script>
    import Portfolio from '@/components/Portfolio';
    import data from '@/common/index';

    export default {
        name: "Home",
        components: {
            Portfolio
        },
        data() {
            return {
                data
            }
        }
    }
</script>

<style scoped>

</style>