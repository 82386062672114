import Vue from 'vue';

const EventBus = new Vue({
    created () {
        this.events = {
            startLoading: 'start-loading',
            finishLoading: 'finish-loading',
            loadProgress: 'load-progress'
        };
    },
    methods: {
        startLoading () {
            this.$emit(this.events.startLoading);
        },
        onStartLoading (fn) {
            this.$on(this.events.startLoading, fn);
        },
        finishLoading () {
            this.$emit(this.events.finishLoading);
        },
        onFinishLoading (fn) {
            this.$on(this.events.finishLoading, fn);
        },
        loadProgress () {
            this.$emit(this.events.loadProgress);
        },
        onLoadProgress (fn) {
            this.$on(this.events.loadProgress, fn);
        }
    }
});

export default EventBus;