import { render, staticRenderFns } from "./Beauty.vue?vue&type=template&id=56b9ca98&scoped=true&"
import script from "./Beauty.vue?vue&type=script&lang=js&"
export * from "./Beauty.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56b9ca98",
  null
  
)

export default component.exports