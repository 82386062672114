<template>
        <transition name="fade">
        <img class="carousel-cell-image"
             :data-flickity-lazyload="src" :alt="alt" />
        </transition>
</template>

<script>
    export default {
        name: 'mcm-image',
        props: ['src', 'alt']
    };
</script>

<style scoped>

</style>