<template>
    <div id="app">

        <Menu />
        <transition :name="transitionName">
            <router-view></router-view>
        </transition>
    </div>
</template>

<script>
import Menu from '@/components/global/Menu.vue'



export default {
  name: 'app',
  components: {
      Menu,
      // Preloader
  },
    data () {
        return {
            transitionName: 'slide-left',
            isToggled: false
        }
    },
    beforeRouteUpdate (to, from, next) {
        const toDepth = to.path.split('/').length;
        const fromDepth = from.path.split('/').length;
        this.transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left';
        next()
    },
    mounted () {

    }
}
</script>

<style>

    @import url('https://fonts.googleapis.com/css?family=Montserrat');

    * {
        font-family: 'Montserrat', sans-serif;
    }

    * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
    }

    html, body
    {
        /*min-height: 100vh;*/
    }

    body {
        margin: 0;
        padding: 0;
        background: #000;
        height: 100%;
        /*overflow: hidden;*/
    }





</style>
