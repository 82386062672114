<template>
    <div>
        <Preloader ></Preloader>
        <flickity  :style="{ height: height + 'px'}" class="carousel" ref="flickity" :options="flickityOptions" >
            <div v-for="(item, idx) of data.images" :key="idx" class="carousel-cell">
                <mcm-image :data-flickity-lazyload="require(`../assets/images/${ item.img }.png`)" />
            </div>
        </flickity>
    </div>
</template>

<script>
    import Flickity from 'vue-flickity';
    import Image from '@/components/base/Image';
    import FizzyUIUtils from 'fizzy-ui-utils';
    import EventBus from '@/utils/EventBus';
    import Preloader from '@/components/Preloader';

    export default {
        name: 'Portfolio',
        props: ['propsData'],
        components: {
            Flickity,
            Preloader,
            "mcm-image": Image,
        },
        data() {
            return {
                count: 0,
                height: 40,
                data: this.propsData,
                flickityOptions: {
                    imagesLoaded: true,
                    percentPosition: false,
                    setGallerySize: false,
                    lazyLoad: 2,
                    resize: false,
                    contain: false,
                    freeScroll: false,
                    prevNextButtons: true,
                    pageDots: true,
                    wrapAround: true,
                    arrowShape: {"x0":25,"x1":55,"y1":40,"x2":65,"y2":40,"x3":35}
                }
            }
        },
        methods: {
            next() {
                this.$refs.flickity.next();
            },

            previous() {
                this.$refs.flickity.previous();
            }
        },
        mounted () {
            const flickity_data = this.$refs.flickity.data('.carousel');
            const flickity = this.$refs.flickity;
            // let count = 0;

            this.height = window.innerHeight;

            this.imgs = this.$el.querySelectorAll('.carousel-cell img');

            this.$refs.flickity.on( 'scroll', () => {
                let sw = flickity_data.slideableWidth,
                    _x = FizzyUIUtils.modulo( flickity_data.x, sw);

                _x -= sw;

                flickity_data.slides.forEach( ( slide, i ) => {
                    let img = this.imgs[i],
                        shift = slide.cells[0].shift,
                        x;

                    //depending on shift, calculate x in different way
                    if (shift === 0) {
                        x = slide.target + _x;
                    }
                    else if (shift === 1) {
                        x = sw + _x + slide.target ;
                    }
                    else {//shift === -1
                        x = -(sw - slide.target) + _x;
                    }

                         img.style[ "transform" ] = 'translateX( ' + x * -1/3  + 'px)';
                });

            });

            this.$refs.flickity.on( 'staticClick', ( event, pointer, cellElement, cellIndex ) => {
                if ( !cellElement ) {
                    return;
                }

                if (cellElement.classList.contains('is-selected')) {
                    cellElement.classList.toggle('is-expanded');
                    const img = cellElement.getElementsByTagName('img');
                    img[0].classList.toggle('is-expanded');
                    flickity.reposition();
                }

                if ( typeof cellIndex === 'number' ) {
                    flickity.selectCell( cellIndex , false, false);
                }

            });

            this.$refs.flickity.on( 'lazyLoad', function(  ) {
                // var img = event.target;
                // console.log( event.type, img.src );
                // count++;
                EventBus.loadProgress();
            });

            this.$refs.flickity.on( 'change', (  ) => {
                flickity_data.slides.forEach( ( slide, i ) => {
                    let img = this.imgs[i];
                    if (img.classList.contains('is-expanded') ) {
                        img.classList.remove('is-expanded');
                        img.parentElement.classList.remove('is-expanded');
                        flickity.reposition();
                    }
                });
            });

            window.addEventListener("optimizedResize",() => {
                setTimeout( () => {
                    flickity.reposition();
                    flickity_data.resize();
                }, 500)

            });

            window.addEventListener("orientationchange",() => {
                setTimeout( () => {
                    flickity.reposition();
                    flickity_data.resize();
                }, 500)

            });

            flickity_data.resize();

            const throttle = function(type, name, obj) {
                obj = obj || window;
                let running = false;
                const func = function() {
                    if (running) { return; }
                    running = true;
                    requestAnimationFrame(function() {
                        obj.dispatchEvent(new CustomEvent(name));
                        running = false;
                    });
                };
                obj.addEventListener(type, func);
            };

            throttle("resize", "optimizedResize");
        }
    }
</script>

<style >
    .carousel {
        background: #000;
        height: 100%;
        overflow-x: hidden;
    }

    .carousel-cell {
        overflow: hidden;
        width: 70%;
        height: 100%;
    }

    @media screen and ( min-width: 768px ) {
        /* half-width cells for larger devices */
        .carousel-cell { width: 30%; }
    }

    @media screen and  (min-device-width : 768px)
    and (max-device-width : 1024px)
    and (orientation: portrait) {
        .carousel-cell { width: 55%; }
    }

    .carousel-cell.is-expanded {
        width: 100% !important;
    }



    .carousel-cell-image.flickity-lazyloaded,
    .carousel-cell-image.flickity-lazyerror {
        opacity: 0.5;
    }

    .carousel-cell-image {
        /*transition: opacity 0.4s;*/
        opacity: 0;
        display: block;
        width: 199%;
        left: -49.5%;
        object-fit: cover;
        font-family: 'object-fit: cover;';
        height: 100%;
        position: relative;
        -webkit-transition: opacity .5s ease;
        transition: opacity .5s ease;
    }

    .carousel-cell.is-selected .carousel-cell-image.flickity-lazyloaded{
        opacity: 1;
    }

    .carousel-cell-image.is-selected{
        opacity: 0.5;
    }

    .carousel-cell img.is-expanded {
        object-fit: contain;

        font-family: 'object-fit: contain;';

    }
    .flickity-prev-next-button {
        position: absolute;
        top: 50%;
        width: 44px;
        height: 44px;
        border: none;
        border-radius: 10%;
        background: #000;
        background: rgba(0, 0, 0, 0.50);
        cursor: pointer;
        opacity: 1;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        -webkit-transition: opacity .5s ease, background-color .5s ease;
        transition: opacity .5s ease, background-color .5s ease;
    }

    .flickity-prev-next-button.previous {
        left: 9px;
    }

    .flickity-prev-next-button.next {
        right: 9px;
    }

    .flickity-prev-next-button svg {
        position: absolute;
        left: 20%;
        top: 20%;
        width: 60%;
        height: 60%;
    }

    .flickity-prev-next-button .arrow {
        fill: #fff;
    }

    .flickity-page-dots {
        position: absolute;
        width: 100%;
        bottom: 25px;
        padding: 0;
        margin: 0;
        list-style: none;
        text-align: center;
        line-height: 1;
        z-index: 98;
    }

    .flickity-page-dots .dot {
        display: inline-block;
        background: #fff;
        opacity: 0.25;
        cursor: pointer;
        height: 4px;
        width: 40px;
        margin: 0;
        border-radius: 0;
    }

    .flickity-page-dots .dot.is-selected {
        opacity: 1;
    }

</style>