<template>
    <div>
        <div class="logo">
            <h1><router-link to="/">Matthew McMullen Smith</router-link></h1>
        </div>
        <div class="button_container" :class="{active: active}" @click="openOverlay" id="toggle">
            <span class="top"></span>
            <span class="middle"></span>
            <span class="bottom"></span>
        </div>

        <div ref="overlay" class="overlay" :class="{open: open}" id="overlay">
            <nav class="overlay-menu">
                <ul>
                    <li><router-link @click.native="openOverlay" to="/">Home</router-link></li>
                    <li><router-link @click.native="openOverlay" to="/smoke-series">Smoke Series</router-link></li>
                    <li><router-link @click.native="openOverlay" to="/fashion">Fashion</router-link></li>
                    <li><router-link @click.native="openOverlay" to="/beauty">Beauty</router-link></li>
                    <li><router-link @click.native="openOverlay" to="/nudes">Nudes</router-link></li>
<!--                    <li><router-link to="/about">About</router-link></li>-->
                </ul>
            </nav>
            <footer class="footer"><p>Contact: 646 226 9504 <a href="mailto:mcmullensmith@gmail.com">mcmullensmith@gmail.com</a></p></footer>
        </div>
    </div>
</template>

<script>
    import { TweenMax, Power2, CSSPlugin } from "gsap/TweenMax";

    const plugins = [ CSSPlugin, Power2 ];


    export default {
        name: "Menu",
        data () {
            return {
                active: false,
                open: false
            }
        },
        methods: {
            openOverlay: function (){

                this.open = !this.open;

                this.active = !this.active;


                if (this.open) {
                    TweenMax.to(".footer", 0.5, {opacity:1, delay: 0.95, ease: Power2.easeIn});
                } else {
                    TweenMax.to(".footer", 0.5, {opacity:0});
                }

            }
        },
        mounted () {
            this.footer = this.$refs.footer
        }
    }
</script>

<style scoped lang="scss">




    $color-background: #F5F5F5;
    $color-main: #ea881c;
    $color-active: #FFF;
    $color-link: #FFF;

    $button-height: 27px;
    $button-width: 35px;



    .logo {
        position: fixed;
        top: 5.5%;
        left: 3%;
        z-index: 100;
        h1 {
            font-size: 1rem;
            color: #ea881c;
            a {
                text-decoration: none;
                color: inherit;
            }
        }
    }

    .button_container {
        position: fixed;
        top: 5%;
        right: 3%;
        height: $button-height;
        width: $button-width;
        cursor: pointer;
        z-index: 101;
        transition: opacity .25s ease;

        &:hover {
            opacity: .7;
        }

        &.active {
            .top {
                transform: translateY(11px) translateX(0) rotate(45deg);
                background: $color-active;
            }
            .middle {
                opacity: 0;
                background: $color-active;
            }

            .bottom {
                transform: translateY(-11px) translateX(0) rotate(-45deg);
                background: $color-active;
            }
        }

        span {
            background: $color-main;
            border: none;
            height: 5px;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            transition:  all .35s ease;
            cursor: pointer;

            &:nth-of-type(2) {
                top: 11px;
            }

            &:nth-of-type(3) {
                top: 22px;
            }
        }
    }


    .overlay {
        position: fixed;
        background: black;
        top: 0;
        left: 0;
        width: 100%;
        height: 0%;
        transition: height .35s;
        overflow: hidden;
        z-index: 100;
        transition-delay: .5s;

        &.open {
             height: 100%;
             transition-delay: 0s;

            li {
                animation: fadeIn 1s ease-in both;

                &:nth-of-type(2) {
                     animation-delay: .4s;
                 }
                &:nth-of-type(3) {
                     animation-delay: .45s;
                 }
                &:nth-of-type(4) {
                     animation-delay: .50s;
                 }

                &:nth-of-type(5) {
                    animation-delay: .55s;
                }

                &:nth-of-type(4) {
                    animation-delay: .60s;
                }
            }
        }
        nav {
            position: relative;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 2rem;
            font-weight: 400;
            text-align: center;
        }
        ul {
            list-style: none;
            padding: 0;
            margin: 0 auto;
            display: inline-block;
            position: relative;
            height: 100%;

            li {

                display: block;
                position: relative;
                opacity: 0;
                margin-bottom: 2rem;

                a {
                    display: block;
                    position: relative;
                    color: $color-link;
                    text-decoration: none;
                    overflow: hidden;

                    &:hover:after,
                    &:focus:after,
                    &:active:after {
                         width: 100%;
                     }

                    &:after {
                         content: '';
                         position: absolute;
                         bottom: 0;
                         left: 50%;
                         width: 0%;
                         transform: translateX(-50%);
                         height: 3px;
                         background: $color-link;
                         transition: .35s;
                    }
                }
            }
        }
    }


    .footer {
        opacity: 0;
    }

    footer {
        color: white;
        position: absolute;
        bottom: 5%;
        text-align: center;
        left: 50%;
        transform: translate(-50%, -50%);
        a {
            display: inline-block;
            color: $color-main;
        }
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
            transform: translate3d(0, -20%, 0);
        }
        to {
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
    }



    @media screen and ( min-width: 768px ) {
        .logo {
            top: 5%;
            left: 2%;
            h1 {
                font-size: 1.3rem;
            }
        }

        .button_container {
            right: 2%;
        }
    }



</style>