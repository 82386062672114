<template>
    <div ref="preloader" class="preloader">
        <div class="preloader__status">
            <div class="preloader__status-text">loading</div>
            <div class="preloader__status-loader">
                <div class="preloader__status-bar"></div>
            </div>
        </div>
    </div>
</template>

<script>
    import EventBus from "@/utils/EventBus";
    import { TweenMax, Power2, CSSPlugin } from "gsap/all";

    const plugins = [ CSSPlugin, Power2 ];

    export default {
        name: "Preloader",
        data() {
            return {
                loaded: 0,
                loading: null,
                loadStyle: {
                    width: '0%'
                },
            }
        },
        watch: {
            loaded(){
                TweenMax.to('.preloader__status-bar', 0.5 ,{width: `${this.loaded}%`, ease: Power2.easeIn});
                if (this.loaded === 100)
                    setTimeout ( () => {
                        this.removePreloader();
                    }, 500);

            }
        },

        methods : {

            updateProgress () {

                if (this.loaded < 100 ) {
                    this.loaded += 20;
                    console.log(this.loaded);
                }
            },

            removePreloader(){

                TweenMax.to(this.preloader, 0.5 ,{top: "-100vh", ease: Power2.easeIn});
            },

        },
        mounted() {
            EventBus.onLoadProgress(this.updateProgress);
            this.preloader = this.$refs.preloader;
            this.body = document.getElementsByTagName('body')[0];
            this.carousel = document.querySelectorAll('.carousel')[0];
        }
    }
</script>

<style scoped lang="scss">
    $primary-color: #000000;

    .preloader{
        width:100%;
        height:100vh;
        background: rgba(0,0,0,0.8);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items:center;
        position: absolute;
        z-index: 99;
        &__status{
            width: 100%;
            display:flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
        }
        &__status-text{
            font-size: 1rem;
            margin-bottom:20px;
            margin-left: 20px;
            color: #999;
        }
        &__status-loader{
            width: 100%;
            height:3px;
        }
        &__status-bar{
            background: #999e;
            height: 100%;
            width: 0;
        }

    }

    .cls-1, .cls-2 {
        fill: white;
    }


</style>