import Vue from 'vue';
import Router from 'vue-router';
import Home from './views/Home';
import Nudes from './views/Nudes';
import Fashion from './views/Fashion';
import Beauty from './views/Beauty';
import Smoke from './views/Smoke';

Vue.use(Router);

export default new Router({
    // base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'home',
            component: Home
        },
        {
            path: '/nudes',
            name: 'nudes',
            component: Nudes
        },
        {
            path: '/fashion',
            name: 'fashion',
            component: Fashion
        },
        {
            path: '/beauty',
            name: 'beauty',
            component: Beauty
        },
        {
            path: '/smoke-series',
            name: 'smoke',
            component: Smoke
        }
    ]
});